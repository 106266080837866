/* eslint-disable @typescript-eslint/no-explicit-any */
export interface CompaniesList {
    // [key: string]: string | number | null;
    login: string;
    name: string;
    id: number | null;
    address: string;
    type: string;
    label: string;
    value: string;
    hasKey: string;
    codeKey: string;
    openingHours: string;
}

const EmptyCompaniesList: CompaniesList = {
    login: '',
    name: '',
    id: 0,
    address: '',
    type: '',
    label: '',
    value: '',
    hasKey: '0',
    codeKey: '',
    openingHours: '',
};

export const CompaniesListBuilder = (values: Partial<CompaniesList> = {}): CompaniesList[] => [
    {
        ...EmptyCompaniesList,
        ...values,
    },
];

export const FormatCompaniesList = ({ data }: any): CompaniesList[] => {
    const array = data.map(
        (item: any): CompaniesList => {
            return {
                login: item.Identifiant,
                name: item.RaisonSociale,
                id: item.id,
                type: item.libelle_fk_TiersType,
                address: item.concatAdresse,
                label: item.RaisonSociale,
                value: item.id,
                hasKey: item.hasKeys === 0 ? 'Non' : 'Oui',
                codeKey: item.numeroCle,
                openingHours: item.openingHours && item.openingHours.replaceAll(';', '\n'),
            };
        },
    );

    return array;
};

export interface Destination {
    Adresse1: null | string;
    Adresse2: null | string;
    CodePostal: null | string;
    Identifiant: null | string;
    RaisonSociale: null | string;
    Ville: null | string;
    commentaire: null | string;
    complementRS: null | string;
    email: null | string;
    fk_tiersDestination: number;
    fk_tiersSource: number;
    openingHours: null | string;
    telephone1: null | string;
    telephone2: null | string;
    adresseComplete: null | string;
}
