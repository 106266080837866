import { Column } from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { FaFile, FaKey, FaPeopleCarry } from 'react-icons/fa';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Icon from 'components/atoms/Icon';
import Card from 'components/molecules/Card';
import TextDeco from 'components/atoms/Text';
import Button from 'components/atoms/Buttons';
import Select from 'components/atoms/Form/Select';
import Table, { RefTable } from 'components/organisms/Table/ReactTable';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { GET, PATCH } from 'utils/Fetch';
import { FormatSelectUser } from 'models/UsersBuilder/UserBuilder';
import { NotificationContext } from 'contexts/Notifications';
import { DeliveriesHistory, FormatDelivery, DeliveryBuilder } from 'models/DeliveryBuilder';

const Delivery = () => {
    const { id } = useParams<{ id: string }>();

    const { dispatch } = useContext(NotificationContext);
    const [delivery, setDelivery] = useState(DeliveryBuilder());
    const [userList, setUserList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const componentRef = useRef(null);

    const GetDelivery = async () => {
        const { status, message, ...response } = await GET(API.DELIVERY + id);
        if (!status) return dispatch({ message, status });
        setDelivery(FormatDelivery(response));
    };

    const GetUserList = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ message, status });
        setUserList(FormatSelectUser(response));
    };

    useEffect(() => {
        GetDelivery();
        GetUserList();
    }, []);

    const ChangeDeliver = async (data: string) => {
        const body = {
            fk_livreur: +data,
            id: id,
        };

        const { status, message } = await PATCH(API.DELIVERY_CHANGE_DELIVER, body);
        dispatch({ status, message });
    };

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `Livraison ${delivery.id}`,
    });

    const saveNewOrder = async () => {
        const body = {
            list: delivery.documentsList,
        };
        const { status, message, ...response } = await PATCH(API.DELIVERY + `/${id}`, body);
        if (!status) return dispatch({ message, status });
        GetDelivery();
    };
    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <div className="col-span-4 lg:col-span-2">
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={`Ajouté le ${delivery.createAt}`}>
                    Livraison {delivery.id}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info="Tournée">
                    {delivery.tourLabel}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaPeopleCarry} info="Livreur">
                    <Select
                        name="deliverer"
                        onChange={({ target }) => ChangeDeliver(target.value)}
                        options={userList}
                        value={delivery.delivererId}
                    />
                </Card>
            </div>
            <Card className="col-span-4 row-start-2 md:row-start-1 lg:col-start-3 lg:col-span-5 flex flex-col">
                <small>Historique</small>
                <Table columns={columns} data={delivery.deliveriesHistory} />
            </Card>
            <Button onClick={handlePrint} variant="outline" className="col-span-1 row-start-3 md:row-start-2">
                imprimer
            </Button>
            <Button onClick={saveNewOrder} variant="default" className="col-span-1 row-start-3 md:row-start-2">
                Sauvegarde
            </Button>
            <div className="col-span-6 row-start-4 md:row-start-3">
                <RefTable
                    dnd
                    setTable={(e: any) =>
                        setDelivery((curr) => {
                            console.log(e);

                            return {
                                ...curr,
                                documentsList: e.map((item: any, index: number) => ({ ...item, id: index + 1 })),
                            };
                        })
                    }
                    ref={componentRef}
                    columns={DocumentList}
                    data={delivery.documentsList}
                />
            </div>
        </section>
    );
};

const CustomCell = (props: {
    value: string;
    row: { original: { status_color: 'success' | 'secondary' | 'info' | 'warning' | 'none' } };
}) => (
    <Fragment>
        <TextDeco text={props.value} color={props.row.original.status_color} />
    </Fragment>
);

const DeliveryCell = (props: { value: string; row: { original: { documentId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DOCUMENTS}/${props.row.original.documentId}`}>
            <p className="text-sm w-auto text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const TiersSource = (props: { value: string; row: { original: { tiersDestId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersDestId}`}>
            <p className="text-sm w-auto text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);
const HasKeys = (props: { value: string; row: { original: { hasKeys: string } } }) => (
    <Fragment>
        <Icon Icon={FaKey} className={props.row.original.hasKeys === 'Non' ? 'text-red-500' : 'text-green-500'} />
    </Fragment>
);

const LigneIndex = (props: { value: string; row: { original: { id: number } } }) => (
    <Fragment>
        <p className="flex justify-center">{props.row.original.id}</p>
    </Fragment>
);

const columns: Column<DeliveriesHistory>[] = [
    {
        Header: 'Date',
        accessor: 'createAt',
    },
    {
        Header: 'État',
        accessor: 'statusLabel',
        Cell: CustomCell,
    },

    {
        Header: 'Livreur',
        accessor: 'delivererName',
    },
];

const DocumentList: Column<DeliveriesHistory>[] = [
    {
        Header: 'Ordre',
        accessor: 'id',
        Cell: LigneIndex,
    },

    {
        Header: 'TiersSource',
        accessor: 'tiersSourceName',
    },
    {
        Header: 'TiersDestination',
        accessor: 'tiersDestName',
        Cell: TiersSource,
    },
    {
        Header: 'Horaires',
        accessor: 'openingHours',
    },
    {
        Header: 'Clé',
        accessor: 'hasKeys',
        Cell: HasKeys,
    },
    {
        Header: "Heure d'arrivée",
        accessor: 'arrivalTime',
    },
    {
        Header: 'Adresse',
        accessor: 'tiersDestAddress',
    },
    {
        Header: 'Document',
        accessor: 'documentNumber',
        Cell: DeliveryCell,
    },
    {
        Header: 'État',
        accessor: 'statusLabel',
        Cell: CustomCell,
    },
    {
        Header: 'Colis',
        accessor: 'countPackage',
    },
];

export default Delivery;
