import classNames from 'classnames';
import React, { FunctionComponent, MutableRefObject, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTable, Column, Row } from 'react-table';
import update from 'immutability-helper';
import Icon from 'components/atoms/Icon';
import { MdOutlineDragHandle } from 'react-icons/md';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

interface TableProps<P extends Record<string, unknown>> {
    columns: Column<any>[];
    data: P[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    getRowProps?: (arg0: Row<any>) => {};
    ref?: MutableRefObject<any>;
    dnd?: boolean;
    setTable?: (table: any) => void;
}

const defaultPropGetter = () => ({});

const Table: FunctionComponent<TableProps<Record<string, unknown>>> = ({
    columns,
    data,
    getRowProps = defaultPropGetter,
    ref,
    dnd = false,
    setTable = () => {},
}) => {
    const [records, setRecords] = React.useState([...data]);
    useEffect(() => {
        setRecords(data);
    }, [data]);
    const getRowId = React.useCallback((row) => {
        return row.id;
    }, []);
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        data: records,
        columns,
        getRowId,
    });
    const moveRow = (dragIndex: string | number | any, hoverIndex: any) => {
        const dragRecord = records[dragIndex];
        setRecords(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            }),
        );
        setTable(
            update(records, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                ],
            }),
        );
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <table ref={ref} className="border border-gray-200 w-full bg-white" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {dnd && <th />}
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    className="text-left border border-gray-200 font-extralight text-gray-500 px-2"
                                    {...column.getHeaderProps()}
                                    key={i}
                                >
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        if (dnd) return <DndRow index={index} row={row} moveRow={moveRow} {...row.getRowProps()} />;
                        return (
                            <tr
                                className={classNames('hover:bg-gray-300', {
                                    '': index % 2 === 1,
                                    'bg-gray-100': index % 2 === 0,
                                })}
                                {...row.getRowProps(getRowProps(row))}
                                key={index}
                            >
                                {row.cells.map((cell, i) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={i}
                                            className={classNames('h-14 px-2 border text-sm')}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </DndProvider>
    );
};

export default Table;

export const RefTable = React.forwardRef((props: any, ref: any) => {
    return (
        <div ref={ref}>
            <style type="text/css" media="print">
                {'\
                    @page {size: A4 portrait; margin: 8mm 10mm 0 10mm !important;}\
                '}
            </style>
            <Table columns={props.columns} data={props.data} {...props} />
        </div>
    );
    RefTable.displayName = 'MyComponent';
});

const DND_ITEM_TYPE = 'row';

export const DndRow = ({ row, index, moveRow }: any) => {
    const dropRef = React.useRef<any>(null);
    const dragRef = React.useRef<any>(null);

    const [, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        hover(item: any, monitor) {
            if (!dropRef.current) {
                return;
            }

            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = dropRef.current.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset ? clientOffset.y - hoverBoundingRect.top : 0;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag, preview]: any = useDrag({
        type: DND_ITEM_TYPE,
        item: { type: DND_ITEM_TYPE, index },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;

    preview(drop(dropRef));
    drag(dragRef);

    return (
        <tr
            className={classNames('hover:bg-gray-300', {
                '': index % 2 === 1,
                'bg-gray-100': index % 2 === 0,
            })}
            ref={dropRef}
            style={{ opacity }}
        >
            <td key={1} ref={dragRef} className="text-center">
                <Icon className="text-center" Icon={MdOutlineDragHandle} />
            </td>
            {row?.cells.map((cell: any, index: number) => {
                return (
                    <td key={index + 1} {...cell.getCellProps()} className={classNames('h-14 px-2 border text-sm')}>
                        {cell.render('Cell')}
                    </td>
                );
            })}
        </tr>
    );
};
