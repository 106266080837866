enum API {
    MY_ACCOUNT = '/moncompte',
    FILES_HISTORY = '/api/v1/fichiers/list',
    FILE = '/api/v1/fichiers/',
    PROCESS_FILES = '/api/v1/fichiers/traitement-fichiers-attente',
    WAITING_FILES = '/api/v1/tiers/fichiers-en-attente',
    START_FILE_PROCESS = '/api/v1/fichiers/traitement-fichiers',

    LOGIN = '/api/v1/login',
    LOGOUT = 'api/v1/logout',

    TIERS_LIST = '/api/v1/tiers/list',
    TIER = '/api/v1/tiers',
    TIER_DELIVERY = '/api/v1/livraisons/pharmacie-list',
    COMPANY_CLOSURE = '/api/v1/fermetures',
    COMPANY_CLOSED_LIST = '/api/v1/fermetures/list',
    TIERS_DESTINATAIRE = '/api/v1/tiers/list_tiersDesSources',
    TIERS_SOURCES = '/api/v1/tiers/list_tiersSources',

    DOCUMENTS_STATUS = '/api/v1/documents/change-document-and-colis-etat',
    DOCUMENTS_HISTORIES = '/api/v1/documents/etats-dashboard',
    DOCUMENTS_LIST = '/api/v1/documents/list',
    DOCUMENT = '/api/v1/documents/',

    PACKAGES_STATUS = '/api/v1/colis/change-colis-etat',
    PACKAGES_LIST = '/api/v1/colis/list',
    PACKAGE = '/api/v1/colis/',
    PACKAGE_STATS_PIE = '/api/v1/colis/statistics/tiers-source-camembert',
    PACKAGE_STATS_BAR = '/api/v1/colis/statistics/count-colis',

    DELIVERY = '/api/v1/livraisons/',
    DELIVERIES_LIST = '/api/v1/livraisons/list',
    DELIVERY_CHANGE_DELIVER = '/api/v1/livraisons/change-livreur-attached',

    USER_LIST = '/api/v1/utilisateurs/list',
    USER_PROFIL = '/api/v1/utilisateurs/',
    USER_SCHEDULE = '/api/v1/horaires-livreurs',
    USER_SCHEDULE_LIST = '/api/v1/horaires-livreurs/list',
    USER_ABSENT = '/api/v1/absences',
    USER_DELETE_ABSENT = '/api/v1/abscences',
    USER_GPS_HISTORY = '/api/v1/position-history/get-user-history',

    ABSENT_MOTIVE = '/api/v1/absences/list-motifs-absences',
    USERS_ABSENT = '/api/v1/absences/list-absences',

    ABSENT_SAVE = '/api/v1/absences',
    TOUR_UPDATE_ORDER = '/api/v1/tournees/save-tournee-order',
    TOUR_LIST = '/api/v1/tournees/list',
    TOUR = '/api/v1/tournees',
    TOUR_START = '/api/v1/tournees/mise-en-livraison',
    TOUR_UPLOAD_FILE = '/api/v1/tournees/upload-file',
    UPLOAD_FILE = '/api/v1/fichiers/upload-and-read-fichier',

    MAP_HISTORY = '/api/v1/livraisons/get-gps-history',

    OPTIONS_COMPANIES_SOURCE = '/api/v1/lists/tiers-source',
    OPTIONS_PACKAGE_TYPE = '/api/v1/lists/colis-type',
    OPTIONS_STATUS = '/api/v1/lists/etats',
    OPTIONS_ROLES = '/api/v1/lists/roles',
    OPTIONS_COMPANIES_TYPE = '/api/v1/lists/tiers-type',
    OPTIONS_CENTRE_LOGISTIQUE = '/api/v1/lists/centre-logistique',

    IA_MODEL = '/api/v1/transports-scheduler/run-model',

    RENVOI_FICHIER = '/api/v1/fichiers/renvoie-fichier',
}

export default API;
