import { TourList } from './TourListBuilder';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TourOrder {
    name: string;
    address: string;
    tiersId: number;
    order: number;
}

export interface TourProps extends TourList {
    tourOrder: TourOrder[];
}

export const TourBuilder = (): TourProps => ({
    description: '',
    label: '',
    deliverName: '',
    deliverId: 0,
    id: 0,
    days: '',
    tourOrder: [{ name: '...', address: '...', tiersId: 0, order: 0 }],
    firstName: '',
    lastName: '',
    codeTournee: '',
    centreLogistique: '',
});

export const FormatTour = ({ data }: any): TourProps => {
    const tourInfo = data.map(
        (data: any): TourProps => {
            const _tourOrder = data.tourneeOrder.map(
                (data: any): TourOrder => ({
                    name: data.RaisonSociale,
                    address: data.concatAdresse,
                    tiersId: data.fk_tiers,
                    order: data.ordre,
                }),
            );
            const _days = data.jours
                .toLowerCase()
                .split(',')
                .map((data: string) => data.trim());

            const convertArrayToObject = (array: any) => {
                const initialValue = {};
                return array.reduce((obj: any, item: any) => {
                    return {
                        ...obj,
                        [item]: true,
                    };
                }, initialValue);
            };

            return {
                firstName: '',
                lastName: '',
                description: data.Description,
                label: data.Libelle,
                deliverName: data.concatNomPrenomLivreur,
                deliverId: data.fk_livreur,
                id: data.id,
                days: convertArrayToObject(_days),
                tourOrder: _tourOrder,
                codeTournee: data.identifiant_tournee,
                centreLogistique: data.fk_centreLogistique,
            };
        },
    );

    return tourInfo[0];
};

interface UnFormatReturn {
    Description: string;
    fk_livreur: number | null;
    Libelle: string;
    jours: string;
    id: number | null;
    identifiant_tournee: string;
    fk_centreLogistique: number | null;
}

export const UnFormatTour = (data: TourList): UnFormatReturn => {
    return {
        Description: data.description,
        fk_livreur: +data.deliverId === 0 ? null : +data.deliverId,
        Libelle: data.label,
        jours: data.days,
        id: data.id,
        identifiant_tournee: data.codeTournee,
        fk_centreLogistique: +data.centreLogistique === 0 ? null : +data.centreLogistique,
    };
};
