import React, { FunctionComponent } from 'react';
import { Popup as LPopup } from 'react-leaflet';
import Icon from 'components/atoms/Icon';
import classNames from 'classnames';
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa';
import { colorScale, DeliveryList } from 'models/MapBuilder';

interface Props {
    primary_key: number;
    secondary_key: number;
    pharmacie: DeliveryList;
    delivery: DeliveryList & any;
    setValue: any;
}
const Popup: FunctionComponent<Props> = ({ primary_key, secondary_key, pharmacie, delivery = {}, setValue }) => {
    return (
        <LPopup
            className="group z-10 hover:z-40 bg-white shadow-lg"
            autoClose={false}
            closeButton={false}
            key={secondary_key}
            closeOnClick={false}
            position={{ lat: pharmacie.latitude, lng: pharmacie.longitude }}
        >
            <>
                <Icon
                    Icon={pharmacie.livrer === 1 ? FaCheckCircle : FaQuestionCircle}
                    className={classNames(
                        'absolute rounded-full -left-2 -top-1 z-50 bg-white text-green-700 text-base',
                        {
                            'text-green-700': pharmacie.livrer === 1,
                            'text-orange-700': pharmacie.livrer === 0,
                        },
                    )}
                />
                <div
                    style={{ backgroundColor: colorScale[primary_key] }}
                    className="relative h-full w-full flex justify-center text-white font-bold cursor-pointer"
                    onClick={() => setValue(delivery)}
                >
                    {secondary_key + 1}
                </div>
                <div
                    className={classNames(
                        'absolute hidden group-hover:min-h-full group-hover:flex flex-col bg-white top-5 group-hover:w-40 p-1 z-40',
                    )}
                >
                    <span className="font-bold break-all">{pharmacie.raisonSocial.toLowerCase()}</span>
                    <span className="text-xs">{pharmacie.adresse.toLowerCase()}</span>
                    <span className="mb-2 text-xs">
                        {pharmacie.codePostale} {pharmacie.ville.toLowerCase()}
                    </span>
                    <span className="mb-2 text-xs break-all">{pharmacie.openingHours}</span>
                    <span>
                        clé:
                        <span className="font-bold ml-1">{pharmacie.hasKeys ? 'Oui' : 'Non'}</span>
                    </span>
                    <span>
                        Livré à:
                        <span className="font-bold ml-1">{pharmacie.date.split(' ')[1]}</span>
                    </span>
                </div>
            </>
        </LPopup>
    );
};

export default Popup;
