import { FieldValues } from 'react-hook-form';

export interface UserProps {
    id: number | null;
    lastName: string;
    firstName: string;
    createdAt: string;
    suppressedAt: string;
    login: string;
    token: string;
    password: string;
    passwordRepeat: string;
    roleId?: string[];
    usurpation?: string[];
    roleLabel: {
        user: string[];
        usurpation: string[];
    };
    userRole: { label: string; value: string }[];
    timetable: number;
    centreLogistique: { label: string; value: string }[];
}

export const UserBuilder = (): UserProps => ({
    id: 0,
    lastName: '',
    firstName: '',
    createdAt: '',
    suppressedAt: '',
    login: '',
    token: '',
    roleId: [],
    roleLabel: {
        user: [],
        usurpation: [],
    },
    userRole: [{ label: '', value: '' }],
    usurpation: [],
    password: '',
    passwordRepeat: '',
    timetable: 0,
    centreLogistique: [],
});

export const FormatSelectUser = ({ data }: any) =>
    data.map((data: any) => ({
        value: data.id,
        label: data.concatNomPrenom,
    }));

export const FormatUser = ({ data }: any): UserProps => {
    const centres = typeof data[0].centres === 'string' ? JSON.parse(data[0].centres) : data[0].centres;
    const roles = typeof data[0].roles === 'string' ? JSON.parse(data[0].roles) : data[0].roles;

    return {
        createdAt: data[0].CreatedAt,
        lastName: data[0].Nom,
        firstName: data[0].Prenom,
        suppressedAt: data[0].SuppressedAt,
        id: data[0].id,
        login: data[0].login,
        token: data[0].token,
        userRole: roles,
        roleLabel: {
            user: [''],
            usurpation: [],
        },
        password: data[0].password,
        passwordRepeat: data[0].passwordRepeat,
        timetable: data[0].fk_horairesLivreurs,
        centreLogistique: centres?.includes(null) ? [] : centres,
    };
};

export const ReFormatUser = ({ userRole, centreLogistique, ...data }: FieldValues) => {
    const list_roles_id: number[] = userRole.map((role: { value: string }) => +role.value);
    const list_center_id: number[] = centreLogistique.map((centre: { value: string }) => centre && +centre?.value);

    const id = data.id === null ? null : parseInt(data.id, 10);
    if (data.id !== null && !data.password) {
        return {
            Prenom: data.firstName,
            list_roles_id: list_roles_id,
            id: id,
            login: data.login,
            Nom: data.lastName,
            centre: list_center_id,
        };
    }
    return {
        Prenom: data.firstName,
        password: data.password,
        list_roles_id: list_roles_id,
        id: id,
        login: data.login,
        Nom: data.lastName,
        centre: list_center_id,
    };
};

export default UserProps;
