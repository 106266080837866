import React, { FunctionComponent, Fragment, useEffect, useState } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { Destination } from 'models/CompanyBuilder/CompaniesList';

interface DestinatairesSourcesProps {
    id: string;
    typeId: string;
}

const DestinatairesSources: FunctionComponent<DestinatairesSourcesProps> = ({ id, typeId }) => {
    const [listeDesSources, setListeDesSources] = useState<Destination[]>([]);

    const GetTiersList = async () => {
        const option = typeId === '1' ? 'source' : 'destinataire';
        const response = await GET(`${API.TIERS_DESTINATAIRE}?type=${option}&id=${id}`);
        if (!response.status) return;
        setListeDesSources(response.data);
    };

    useEffect(() => {
        GetTiersList();
    }, []);

    const MailTo = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => (
        <Fragment>
            <span className="flex justify-between">
                <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:${props.value}`}>
                    {props.value}
                </a>
            </span>
        </Fragment>
    );

    const viewHoraire = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => {
        let value = [props.value];
        if (props.value) value = props.value.split(';');
        return (
            <Fragment>
                {value.map((row, i) => (
                    <p key={i}>
                        {row}
                        <br />
                    </p>
                ))}
            </Fragment>
        );
    };

    const LocationCell = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => {
        return (
            <Fragment>
                <span className="flex justify-between">
                    <span>
                        <span className="text-sm pl-2">{props.value}</span>
                    </span>

                    <a
                        className="text-sm text-purple-900 hover:underline"
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${props.value}`}
                        rel="noreferrer"
                    >
                        <FaMapMarkedAlt className="text-lg" />
                    </a>
                </span>
            </Fragment>
        );
    };

    const columns = [
        {
            Header: 'Identifiant',
            accessor: 'Identifiant',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Raison Social',
            accessor: 'RaisonSociale',
            className: 'text-left border border-gray-200  px-2  w-2/12',
        },
        {
            Header: 'Mail',
            accessor: 'email',
            Cell: MailTo,
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },

        {
            Header: 'Ville',
            accessor: 'adresseComplete',
            className: 'text-left border border-gray-200  px-2 w-3/12',
            Cell: LocationCell,
        },
        {
            Header: 'Commentaire',
            accessor: 'commentaire',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Horaire',
            accessor: 'openingHours',
            Cell: viewHoraire,
            className: 'text-left border border-gray-200  px-2 w-2/12',
        },
    ];

    return (
        <section className="mx-10 my-5 w-[100%]">
            {listeDesSources.length !== 0 ? (
                <Table columns={columns} data={listeDesSources} />
            ) : (
                <p>Pas de données dans la base de données</p>
            )}
        </section>
    );
};

export default DestinatairesSources;
