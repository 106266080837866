import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface LibelleProps {
    text: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
}

const Label: FunctionComponent<LibelleProps> = ({ text, color }) => {
    return (
        <span
            className={classNames('text-sm text-black p-1', {
                'bg-green-500': color === 'success',
                'bg-purple-600': color === 'secondary',
                'bg-blue-500': color === 'info',
                'bg-yellow-500': color === 'warning',
            })}
        >
            {text}
        </span>
    );
};

export default Label;
