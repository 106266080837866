import { Link, useNavigate } from 'react-router-dom';
import { FaTrash } from 'react-icons/fa';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Button from 'components/atoms/Buttons';
import Modal from 'components/organisms/Modal';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import TourListProps, { FormatTourList, TourListBuilder } from 'models/TourListBuilder';
import { UPLOAD } from 'utils/Fetch/upload';
import { NotificationContext } from 'contexts/Notifications';

const TourList = () => {
    const { dispatch } = useContext(NotificationContext);
    const [tourList, setTourList] = useState<TourListProps[]>(TourListBuilder());
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [tourId, setTourId] = useState<string>('');
    const navigate = useNavigate();

    const GetUserList = async () => {
        const { status, ...response } = await GET(API.TOUR_LIST);
        if (status) setTourList(FormatTourList(response));
    };

    useEffect(() => {
        if (!modalIsOpen) GetUserList();
    }, [modalIsOpen]);

    const onUpload = async (file: FileList | null) => {
        if (!file) return;
        const { status, message } = await UPLOAD(API.TOUR_UPLOAD_FILE, file);
        if (status) GetUserList();
        return dispatch({ status, message });
    };

    const renderInfo = (a: string, b: string) => {
        console.log(a, b);
        switch (true) {
            case !!a && !b:
                return a;
            case !!b && !a:
                return b;
            case !!a && !!b:
                return `${a} - ${b}`;
            default:
                break;
        }
    };

    const tourneesCells = (props: {
        column: { Header: string };
        value: string;
        row: { original: { id: string; deliverName: string; codeTournee: string; centreLogistique: string } };
    }) => (
        <Fragment>
            <div className="flex justify-between">
                <span className="flex flex-col justify-between w-full">
                    <Link to={`${Routes.TOUR}/${props.row.original.id}`}>
                        <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
                    </Link>
                    <p>Livreur: {props.row.original.deliverName}</p>
                    <p className="text-sm italic text-gray-500">
                        {renderInfo(props.row.original?.codeTournee, props.row.original.centreLogistique)}
                    </p>
                </span>
                <button
                    type="button"
                    className="outlined-none text-red-600"
                    onClick={() => {
                        setModalIsOpen(true);
                        setTourId(props.row.original.id);
                    }}
                >
                    <FaTrash />
                </button>
            </div>
        </Fragment>
    );

    const descriptionCells = (props: { value: string; row: { original: { days: string } } }) => (
        <Fragment>
            <div className="flex justify-between flex-col">
                <p>{props.value}</p>
                <span className="text-sm italic text-gray-500">{props.row.original.days}</span>
            </div>
        </Fragment>
    );

    const columns = [
        {
            Header: 'Tournées',
            accessor: 'label',
            Cell: tourneesCells,
            className: 'text-left border border-gray-200  px-2 w-1/3',
        },
        {
            Header: 'Déscription',
            accessor: 'description',
            Cell: descriptionCells,
            className: 'text-left border border-gray-200  px-2  w-2/3',
        },
    ];

    return (
        <section className="container mx-auto my-5">
            <div className="flex">
                <Button onClick={() => navigate(`${Routes.TOUR}/0`)}>Ajouter une tournée</Button>
                <label
                    className="flex items-center justify-center px-4 py-2 mr-1 text-sm rounded-md shadow outline-none bg-white  border-2 border-gray-400 text-gray-400 focus:outline-none hover:border-purple-700 hover:text-purple-700"
                    htmlFor="file-upload"
                >
                    Import
                </label>
                <input
                    id="file-upload"
                    type="file"
                    className="hidden"
                    onChange={({ target }) => onUpload(target.files)}
                />
            </div>
            <Table columns={columns} data={tourList} sortBy="label" />
            <Modal open={modalIsOpen} setOpen={setModalIsOpen} url={`${API.TOUR}/${tourId}`} />
        </section>
    );
};

export default TourList;
