/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HistoryProps {
    [key: string]: string;
    status_id: string;
    status_label: string;
    status_description: string;
    status_color: string;
    status_forType: string;
    counter: string;
    label: string;
}

export const homeListBuilder = (values: Partial<HistoryProps> = {}): HistoryProps[] => [
    {
        status_id: '',
        status_label: '',
        status_description: '',
        status_color: '',
        status_forType: '',
        counter: '',
        label: '',
        ...values,
    },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatHistory = ({ data }: any): HistoryProps[] => {
    const array = data.map(
        (data: any): HistoryProps => {
            return {
                status_id: data.id,
                status_label: data.label,
                status_description: data.description,
                status_color: data.couleur,
                status_forType: data.forType,
                counter: data.counter,
                label: data.Libelle,
            };
        },
    );

    return array;
};
