/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import Button from 'components/atoms/Buttons';
import Icon from 'components/atoms/Icon';
import React, { forwardRef, FunctionComponent, useEffect, useRef } from 'react';
import { FaSort, FaSortDown, FaSortUp, FaSpinner } from 'react-icons/fa';
import {
    useTable,
    useSortBy,
    usePagination,
    useGlobalFilter,
    useAsyncDebounce,
    Column,
    Row,
    useRowSelect,
} from 'react-table';

interface TableProps<P extends Record<string, unknown>> {
    columns: Column<any>[];
    data: P[];
    nbTotalRows: number;
    getRowProps?: (arg0: Row<any>) => {};
    getHeaderProps?: (arg0: Row<any>) => {};
    getColumnProps?: (arg0: Row<any>) => {};
    fetchData?: any;
    loading: boolean;
    pageCount: any;
    sortBy?: string;
    checkBox?: boolean;
    defaultParams?: any;
    SETFILTER?: (arg0: { [x: string]: string | number | [{ id: string; desc: boolean }] }) => void;
}

const defaultPropGetter = () => ({});

const GlobalFilter = ({ globalFilter, setFilter, nbTotalRows }: any) => {
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setFilter(value);
    }, 200);

    return (
        <span>
            Recherche:
            <input
                className="px-2 mx-2 border-b-2 border-gray-400 outline-none focus:border-purple-800 focus:shadow-xl"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${nbTotalRows} éléments...`}
            />
        </span>
    );
};

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
    const defaultRef = useRef<boolean>(null);
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
});

const Table: FunctionComponent<TableProps<Record<string, unknown>>> = ({
    columns,
    data,
    loading,
    pageCount: controlledPageCount,
    getRowProps = defaultPropGetter,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    checkBox = false,
    nbTotalRows,
    defaultParams,
    SETFILTER = () => {},
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        setSortBy,
        selectedFlatRows,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: defaultParams,
            state: defaultParams,
            loading,
            manualPagination: true,
            pageCount: controlledPageCount,
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (!checkBox) return;
            hooks.visibleColumns.push((columns) => [
                // Let's make a column for selection
                {
                    id: 'selection',
                    // The header can use the table's getToggleAllRowsSelectedProps method
                    // to render a checkbox

                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                        </div>
                    ),
                    // The cell can use the individual row's getToggleRowSelectedProps method
                    // to the render a checkbox
                    Cell: ({ row }) => (
                        <div>
                            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                        </div>
                    ),
                },
                ...columns,
            ]);
        },
    );

    const Tbody = () => {
        if (loading)
            return (
                <tbody>
                    <tr>
                        <td>
                            <Icon className="animate-spin" Icon={FaSpinner} />
                        </td>
                    </tr>
                </tbody>
            );
        if (data.length === 0)
            return (
                <tbody>
                    <tr>
                        <td>Pas de resultat</td>
                    </tr>
                </tbody>
            );
        return (
            <tbody {...getTableBodyProps()}>
                {page.map((row, index) => {
                    prepareRow(row);
                    return (
                        <tr
                            className={classNames('border-black hover:bg-gray-300', {
                                '': index % 2 === 1,
                                'bg-gray-100': index % 2 === 0,
                            })}
                            {...row.getRowProps(getRowProps(row))}
                            key={index}
                        >
                            {row.cells.map((cell, i) => {
                                return (
                                    <td {...cell.getCellProps()} key={i} className={classNames('h-14 px-2 text-sm')}>
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    useEffect(() => {
        setSortBy(defaultParams.sortBy);
        setPageSize(defaultParams.pageSize);
        gotoPage(defaultParams.pageIndex);
    }, [defaultParams]);

    return (
        <>
            {selectedFlatRows.length > 0 && (
                <Button variant="secondary" className="font-bold">
                    Supprimer
                </Button>
            )}
            <div className="flex justify-between mt-5 mb-2">
                <span>
                    Afficher
                    <select
                        value={pageSize}
                        className="border border-gray-400 rounded-md w-16 p-1 outline-none mx-2"
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                            SETFILTER({ pageSize: +e.target.value });
                        }}
                    >
                        {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    éléments
                </span>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    setFilter={(e: string) => SETFILTER({ globalFilter: e })}
                    nbTotalRows={nbTotalRows}
                />
            </div>
            <table {...getTableProps()} className="min-w-full table-fixed">
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    className="cursor-pointer font-extralight text-gray-500"
                                    {...column.getHeaderProps([
                                        column.getSortByToggleProps(),
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        getColumnProps(column),
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        getHeaderProps(column),
                                        {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            className: column.className,
                                        },
                                    ])}
                                    onClick={() => {
                                        setSortBy([{ id: column.id, desc: !column.isSortedDesc }]);
                                        SETFILTER({
                                            sortBy: [{ id: column.id, desc: !column.isSortedDesc }],
                                        });
                                    }}
                                    key={i}
                                >
                                    <>
                                        <span className="flex pl-2 items-center justify-between">
                                            {column.render('Header')}
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <Icon Icon={FaSortUp} />
                                                ) : (
                                                    <Icon Icon={FaSortDown} />
                                                )
                                            ) : (
                                                <Icon className="text-gray-400" Icon={FaSort} />
                                            )}
                                        </span>
                                    </>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <Tbody />
            </table>
            <div className="flex justify-end w-full mt-2">
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => {
                        gotoPage(0);
                        SETFILTER({ pageIndex: 0 });
                    }}
                    disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => {
                        previousPage(), SETFILTER({ pageIndex: pageIndex - 1 });
                    }}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => {
                        nextPage();
                        SETFILTER({ pageIndex: pageIndex + 1 });
                    }}
                    disabled={!canNextPage}
                >
                    {'>'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => {
                        gotoPage(pageCount - 1);
                        SETFILTER({ pageIndex: pageCount - 1 });
                    }}
                    disabled={!canNextPage}
                >
                    {'>>'}
                </button>
                <span className="flex items-center text-sm">
                    <span className="pr-2">Page</span>
                    <strong>
                        {+pageIndex + 1} sur {pageOptions.length + 1}
                    </strong>
                </span>
            </div>
        </>
    );
};

export default Table;
