import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';
import Label from 'components/atoms/Text';
import Routes from 'constants/Routes';

const DeliveryCell = (props: { value: string; row: { original: { deliveryId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DELIVERY}/${props.row.original.deliveryId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const StatusCell = (props: {
    value: string;
    row: {
        original: {
            id: string;
            longitude: string;
            latitude: string;
            dateCreation: string;
            color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
        };
    };
}) => (
    <Fragment>
        <span className="flex justify-between">
            <Label text={props.value} color={props.row.original.color} />
        </span>
    </Fragment>
);

export const deliveryColumn = [
    {
        Header: 'Numéro livraison',
        accessor: 'deliveryId',
        Cell: DeliveryCell,
    },
    {
        Header: 'Tournée',
        accessor: 'tourLabel',
    },
    {
        Header: 'Livreur',
        accessor: 'delivererName',
    },

    {
        Header: 'Date',
        accessor: 'createAt',
    },
    {
        Header: 'Etat',
        accessor: 'statusLabel',
        Cell: StatusCell,
    },
];
