import { Link } from 'react-router-dom';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FaUserPlus, FaFileCsv, FaTrash } from 'react-icons/fa';

import ConfirmDelete from './ConfirmDelete';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Buttons';
import LocationCell from 'components/atoms/LocationCell';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { DELETE, GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import { ExportCSV } from 'utils/ExportCSV';
import { FormatCompaniesList, CompaniesListBuilder, CompaniesList } from 'models/CompanyBuilder/CompaniesList';
import CloseForm from './CloseForm';
import { NotificationContext } from 'contexts/Notifications';

const CompaniesListPage = () => {
    const { dispatch } = useContext(NotificationContext);

    const [tiersList, setTiersList] = useState<CompaniesList[]>(CompaniesListBuilder());
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [closeForm, setCloseForm] = useState<boolean>(false);
    const [tiersId, setTiersId] = useState<string>('');

    const GetTiersList = async () => {
        const { status, ...response } = await GET(API.TIERS_LIST);
        if (!status) return;
        setTiersList(FormatCompaniesList(response));
    };

    useEffect(() => {
        GetTiersList();
    }, []);

    const DeleteTiers = async (tiersId: string) => {
        const { status, message } = await DELETE(`${API.TIER}/${tiersId}`);
        dispatch({ status, message });
        setOpenModal(!status);
    };

    const IdCell = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => (
        <Fragment>
            <span className="flex justify-between">
                <Link to={`${Routes.COMPANY}/${props.row.original.id}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.value}</p>
                </Link>
                {props.column.Header === 'Identifiant' && (
                    <button
                        type="button"
                        className="outlined-none text-red-600"
                        onClick={() => {
                            setOpenModal(true);
                            setTiersId(props.row.original.id);
                        }}
                    >
                        <FaTrash />
                    </button>
                )}
            </span>
        </Fragment>
    );

    const columns = [
        {
            Header: 'Identifiant',
            accessor: 'login',
            Cell: IdCell,
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Raison Social',
            accessor: 'name',
            Cell: IdCell,
            className: 'text-left border border-gray-200  px-2  w-2/12',
        },
        {
            Header: 'Ville',
            accessor: 'address',
            className: 'text-left border border-gray-200  px-2 w-4/12',
            Cell: LocationCell,
        },
        {
            Header: 'Clé',
            accessor: 'hasKey',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Code-Clé',
            accessor: 'codeKey',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Horaire',
            accessor: 'openingHours',
            className: 'text-left border border-gray-200  px-2 w-2/12 max-w-sm truncate',
        },
        {
            Header: 'Type',
            accessor: 'type',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex items-center justify-end">
                <Button variant="outline" onClick={() => setCloseForm(true)}>
                    Créer fermeture
                </Button>
                <Button onClick={() => ExportCSV(tiersList)}>
                    <Icon Icon={FaFileCsv} className="w-5" />
                    Exporter les tiers
                </Button>
                <Link to={`${Routes.COMPANY}/0`}>
                    <Button>
                        <Icon Icon={FaUserPlus} className="w-5" />
                        Ajouter un tiers
                    </Button>
                </Link>
            </div>
            <Table columns={columns} data={tiersList} />
            <ConfirmDelete open={openModal} setOpen={setOpenModal} id={tiersId} Delete={DeleteTiers} label="company" />
            <CloseForm open={closeForm} setOpen={setCloseForm} />
        </section>
    );
};

export default CompaniesListPage;
